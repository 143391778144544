@tailwind base;
@tailwind components;
@tailwind utilities;

*{ user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #babdbc;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0db9fd;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1323fa;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root{
    position: fixed;
    top:0;
    left: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: radial-gradient(#4a4a4a,#212121)
}

.sidebarscene{
  background: radial-gradient(#4a4a4a,#212121)
}

.annotationDescription{
    display: block;
    font-weight: 600;
    font-family: Arial, sans-serif; /* Font family */
    font-size: 1rem;
    text-align: center;
    margin: 0;
    background-color: #67646dce;
    margin: 0;
    padding: 2px;
    width: fit-content;
    min-width: 100px;
    max-width: 180px;
    height: auto;
    background-color: black;
    color: #fff;
    position: relative;
    cursor: pointer;
    border-radius: 5px; /* Rounded corners */
    align-content: center;
    align-items: center;
    text-align: center !important; 
}

.div-container {
    background-color: #00AEEF; /* Light blue background */
    color: white; /* White text color */
    font-family: Arial, sans-serif; /* Font family */
    font-size: 24px; /* Font size */
    font-weight: bold; /* Bold text */
    padding: 20px; /* Padding inside the div */
    border-radius: 10px; /* Rounded corners */
    text-align: center; /* Centered text */
    width: fit-content; /* Fit the width to the content */
    margin: 10px; /* Margin around the div */
}

.annotationBody{
    height: fit-content;
    width: fit-content;
    display: flex;
    background-color: aliceblue;
    border: 1px solid white;
    margin: 0;
    padding: 2px;
    border-radius: 5px; /* Rounded corners */
    align-content: center;
}

.annotation:hover{
    background-color: #4a4a4a;
    fill: #4a4a4a;
}



  button {
    width: 200px;
    color: white;
    background-color: #303030;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 6px 12px;
    margin: 4px;
    font-size: 14px;
    border-radius: 4px;
    transition: opacity 0.3s;
  }
  button:hover {
    opacity: 0.7;
    background-color: 606060;
  }

  #info {
    position: absolute;
    bottom: 50px;
    left: 10px;
    width: fit-content;
    color: white;
    background-color: #07070786;
    display: inline-block;
    font-weight: 400;
    text-align: left;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 6px 12px;
    margin: 4px;
    font-size: 14px;
    border-radius: 4px;
    transition: opacity 0.3s;
    z-index: 50;
  }

  #object_details {
    position: absolute;
    bottom: 80px;
    left: 10px;
    width: fit-content;
    color: white;
    background-color: #07070786;
    display: inline-block;
    font-weight: 400;
    text-align: left;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 6px 12px;
    margin: 4px;
    font-size: 14px;
    border-radius: 4px;
    transition: opacity 0.3s;
    z-index: 50;
  }



  #actions {
    position: absolute;    
    display: grid;
    grid-template-columns: repeat(6,3rem);
    background-color: #222;
    border-radius: 1rem;
    padding: 0.5rem;
    bottom: 10px;
    left: 50%;
    z-index: 50;
  }
  #actions button.active{
    display: grid;
    width: fit-content;
    height: fit-content;
    background-color: rgba(83, 231, 95, 0.884);
    color: rgb(0, 0, 0);
    border: 1px solid rgb(109, 106, 106);
    border-radius: 10px;
  }


  #actions button{
    display: grid;
    width: fit-content;
    height: fit-content;
    background-color: rgb(22, 22, 22);
    border: 1px solid rgb(109, 106, 106);
    color: rgb(255, 255, 255);
    border-radius: 10px;
  }

  #actions button:hover{
    display: grid;
    width: fit-content;
    height: fit-content;
    background-color: rgb(209, 208, 208);
    border: 1px solid rgb(109, 106, 106);
    color: rgb(255, 255, 255);
    border-radius: 10px;
  }

  .objectDetails{
    display: block;
    font-weight: 600;
    font-family: Arial, sans-serif; /* Font family */
    font-size: 1rem;
    text-align: center;
    margin: 0;
    background-color: #67646dce;
    margin: 0;
    padding: 2px;
    width: fit-content;
    min-width: 100px;
    height: auto;
    background-color: black;
    color: #fff;
    position: relative;
    cursor: pointer;
    border-radius: 5px; /* Rounded corners */
    border: 1px solid rgb(109, 106, 106);
    align-content: center;
    align-items: center;
    text-align: center !important; 
  }

  .detailsHeader{
    font-weight: 100;
    font-family: Arial, sans-serif; /* Font family */
    font-size: 0.6rem;
    text-align: center;
  }

  .detailsTable{
    font-weight: 100;
    font-family: Arial, sans-serif; /* Font family */
    font-size: 0.6rem;
    text-align: left;
    border: 1px solid rgb(95, 95, 95);
  }

  .detailsTable td{
    font-weight: 100;
    font-family: Arial, sans-serif; /* Font family */
    font-size: 0.6rem;
    text-align: left;
    border: 1px solid rgba(221, 210, 210, 0.473);
  }


  .colorPicker{
    position: absolute;
    z-index: 2000;
    bottom: 50px;
    left: 10px;

  }

  .perf{
    position: absolute;
    left: 40px;
    top: 10px;
    width: fit-content;
  }


